import {
	http,
	http1,
} from "./index";


/*++++++++++++++++++++++++++++++++ 公共接口 ++++++++++++++++++++++++++++++++*/

//获取站点信息
export function getSpotDetail(data) {
	return http1("/index/site/getDetails", data)
}

//获取站点列表
export function getSpotList(data) {
	return http1("/index/site/getLists", data)
}

//  微信jssdk
export function getWxJssdkConfig(data) {
	return http1("/index/Wechat/index", data)
}

//上传微信文件（单） file文件流
export const uploadWxFileSingle = (data) => {
	return http1("index/Wechat/getWxImage", data)
}

//上传文件（单） file文件流
export const uploadFileSingle = (data) => {
	return http1("uploads/Image/single", data)
}

//  获取银行卡配置
export function getBankLists(data) {
	return http1("index/index/getBankLists", data)
}


/*++++++++++++++++++++++++++++++++ 注册登录续期 ++++++++++++++++++++++++++++++++*/


// 新登录测试
export function loginUser(data) {
	return http1("/user/user/login", data)
}

// 新注册测试
export function registerUser(data) {
	return http1("/user/user/register", data)
}

// token过期重新获取,更新token
export function updateToken(data) {
	return http1("/user/user/getToken", data)
}


/*++++++++++++++++++++++++++++++++ 首页接口 ++++++++++++++++++++++++++++++++*/


//  获取 banner轮播图
export function lunbo(data) {
	return http("/basics/banner/getHome", data)
}

//  获取栏目数据 
export function column(data) {
	return http("/basics/column/getHome", data)
}

//  获取公告数据 
export function bulletin(data) {
	return http("/basics/bulletin/getHome", data)
}

//  获取公告详情数据 
export function bulletinDetails(data) {
	return http("/basics/bulletin/getDetails", data)
}


/*++++++++++++++++++++++++++++++++ 公共内容 ++++++++++++++++++++++++++++++++*/


// 用户协议
export function getUserRule(data) {
	return http("/basics/News/user", data)
}

// 达人协议
export function getTalentRule(data) {
	return http("/basics/News/daren", data)
}

// 奖励说明
export function getRewardRule(data) {
	return http("/basics/News/jiangli", data)
}

// 饭佣规则
export function getMoneyRule(data) {
	return http("/basics/News/yongjin", data)
}


//新手问答
export function wenDaList(data) {
	return http("/basics/News/wenda", data)
}

//商家常见问题
export function shopwenda(data) {
	return http("/basics/News/shopwenda", data)
}

//相关协议列表
export function AgreementList(data) {
	return http("/basics/News/xieyi", data)
}

//内容详情
export function ContentDetail(data) {
	return http("/basics/News/getDetails", data)
}



/*++++++++++++++++++++++++++++++++ 商学院 ++++++++++++++++++++++++++++++++*/


//商学院轮播
export function BuesinesSchool() {
	return http("/article/banner/getHome")
}
//商学院分类
export function SchoolClassIfy() {
	return http("/article/cate/getHome")
}
//商学院推荐
export function SchoolRecommend(data) {
	return http("/article/article/getRecomm", data)
}
//商学院分类列表
export function SchoolClassIfyList(data) {
	return http("/article/article/getCateLists", data)
}
//商学院详情
export function SchoolDetail(data) {
	return http("/article/article/getDetails", data)
}
//商学院详情点赞
export function SchoolArticleLike(data) {
	return http("/article/article/setLike", data)
}
//商学院详情取消点赞
export function SchoolArticleCancelLike(data) {
	return http("/article/article/cancelLike", data)
}



/*++++++++++++++++++++++++++++++++ 卡券商品接口 ++++++++++++++++++++++++++++++++*/




//  获取今日爆款商品列表（卡券商品）
export function cardsListsBySales(data) {
	return http("/mall/goods/getCardsListsBySales", data)
}

// 获取热销榜单商品列表（卡券商品）
export function getCardsListsByHotSales(data) {
	return http("mall/goods/getCardsListsByHotSales", data)
}

// 获取即将下架商品列表（卡券商品）
export function getCardsListsByOffSales(data) {
	return http("mall/goods/getCardsListsByOffSales", data)
}


// 首页 获取今日爆款商品（卡券商品）
export function cardsListsByDistance(data) {
	return http("/mall/goods/getCardsListsByDistance", data)
}

// 获取饭佣最多商品列表（卡券商品）【废弃】
export function getCardsListsByCommission(data) {
	return http("mall/goods/getCardsListsByCommission", data)
}


//	搜索关键词
export function getSearchKeyword(data) {
	return http("/mall/goods/getSearchKeyword", data)
}

//搜索关键词
export function SearchKeyWord(data) {
	return http("/mall/goods/getCardsListsBySearch", data)
}

//  卡券商品分类列表 
export function classIfy(data) {
	return http("/mall/cate/getCards", data)
}

//  卡券商品分类下商品
export function classIfyGoods(data) {
	return http("/mall/goods/getCardsListsByCate", data)
}

//  商品详情(卡券)
export function goodsDetails(data) {
	return http("/mall/goods/getCardsDetails", data)
}

//  获取商品店铺列表
export function getGoodsLists(data) {
	return http("/shop/shop/getGoodsLists", data)
}

//  店铺详情（卡券店铺）
export function StoreDetail(data) {
	return http("/shop/shop/getDetails", data)
}

//  获取店铺商品（卡券商品）
export function StoreGoods(data) {
	return http("/mall/goods/getCardsListsByShop", data)
}

// 获取图文详情
export function getGoodsContent(data) {
	return http("mall/goods/getGoodsContent", data)
}



/*++++++++++++++++++++++++++++++++ 实物商品接口 ++++++++++++++++++++++++++++++++*/




// 获取商品分类 （商品列表）
export function getGoods(data) {
	return http("mall/cate/getGoods", data)
}

// 获取分类商品列表（实物商品）
export function getGoodsListsByCate(data) {
	return http("mall/goods/getGoodsListsByCate", data)
}

// 获取推荐商品列表（实物商品）
export function getGoodsListsByRecomm(data) {
	return http("mall/goods/getGoodsListsByRecomm", data)
}

// 获取检索商品列表（实物商品）
export function getGoodsSearchKey(data) {
	return http("mall/goods/getGoodsListsBySearch", data)
}

// 商品详情 （实物详情）
export function getGoodsDetails(data) {
	return http("mall/goods/getGoodsDetails", data)
}



//  立即抢购提交（卡券商品）
export function checkCards(data) {
	return http("/mall/goods/checkGoods", data)
}






/*++++++++++++++++++++++++++++++++ 用户 ++++++++++++++++++++++++++++++++*/

//  推荐人信息
export function getUserInfo(data) {
	return http1("/user/user/getUserInfo", data)
}
// 获取用户个人信息
export function getUser(data) {
	return http1("/user/user/getUser", data)
}

// 修改用户个人信息
export function updataUser(data) {
	return http1("/user/user/updataUser", data)
}

//修改用户头像
export function updataHeadimgurl(data) {
	return http("user/user/updataHeadimgurl", data);
}

// 用户修改个人资料获取验证码
export function sendsmsUpdateUser(data) {
	return http("/user/user/sendsmsUpdateUser", data)
}

// 获取用户的钱包和收益
export function getUserStatistics(data) {
	return http("/user/user/getStatistics", data)
}

// 用户达人注册获取验证码
export function sendsmsBindUser(data) {
	return http("/user/user/sendsmsBindUser", data)
}

// 注册达人（更新用户信息）
export function updataInviteUser(data) {
	return http("/user/user/bindUser", data)
}

// 绑定用户团队
export function bindTeam(data) {
	return http1("/user/team/bindTeam", data)
}


/*++++++++++++++++++++++++++++++++ 用户地址 ++++++++++++++++++++++++++++++++*/


// 获取地址列表
export function getAddressList(data) {
	return http("user/Address/getLists", data)
}

// 获取地址详情
export function getAddressDetail(data) {
	return http1("user/Address/getDetails", data)
}

// 添加地址
export function createAddress(data) {
	return http1("user/Address/create", data)
}

// 删除地址
export function removeAddress(data) {
	return http1("user/Address/remove", data)
}

// 修改地址
export function updateAddress(data) {
	return http1("user/Address/update", data)
}






/*++++++++++++++++++++++++++++++++ 卡券订单 ++++++++++++++++++++++++++++++++*/



//  获取订单结算信息（卡券）
export function cardsPreparePay(data) {
	return http("/order/cards/preparePay", data)
}

//提交支付（卡券）
export function cardsPayOrder(data) {
	return http("/order/cards/payOrder", data)
}

// 执行模拟支付（卡券）
export function imitateOrderCardsPay(data) {
	//return http("order/cards/pay", data)
}

//获取订单列表(卡券)
export function AllCardsOrder(data) {
	return http("/order/cards/getLists", data)
}

//订单详情
export function CardsOrderDetail(data) {
	return http("/order/cards/getDetails", data)
}

//  获取订单详情（卡券列表）
export function getkaqunaLists(data) {
	return http("/order/cards/getCardsLists", data)
}

// 取消订单（卡券）（使用场景客户取消支付提交执行）
export function closeCardsOrder(data) {
	return http1("order/cards/closeOrder", data)
}

// 删除订单（卡券） 
export function deleteCardsOrder(data) {
	return http1("order/cards/deleteOrder", data)
}


// 获取订单商品预约日期和店铺列表
export function getReserveDates(data) {
	return http("mall/reserve/getDates", data)
}

// 获取订单商品预约时间段和店铺详情
export function getReserveTimes(data) {
	return http("mall/reserve/getTimes", data)
}

// 提交预约
export function orderReserve(data) {
	return http1("order/reserve/create", data)
}

// 取消预约
export function orderReserveCancel(data) {
	return http1("order/reserve/cancel", data)
}




/*++++++++++++++++++++++++++++++++ 实物订单 ++++++++++++++++++++++++++++++++*/


// 获取订单结算信息（实物）
export function getGoodsPreparePay(data) {
	return http("order/Goods/preparePay", data)
}

// 提交支付（实物）
export function payOrderGoods(data) {
	return http("order/Goods/payOrder", data)
}

// 执行模拟支付（实物）
export function payGoods(data) {
	return http("order/Goods/pay", data)
}

// 获取订单列表（实物）
export function getGoodsOrderList(data) {
	return http("order/Goods/getLists", data)
}

// 获取订单详情（实物）
export function getGoodsOrderDetails(data) {
	return http("order/Goods/getDetails", data)
}
 
// 取消订单（实物）
export function closeGoodsOrder(data) {
	return http1("order/Goods/closeOrder", data)
}

// 删除订单（实物）
export function deleteGoodsOrder(data) {
	return http1("order/Goods/deleteOrder", data)
}

// 订单收货（实物）
export function receiveGoodsOrder(data) {
	return http1("order/Goods/receiveOrder", data)
}


/*++++++++++++++++++++++++++++++++ 售后订单 ++++++++++++++++++++++++++++++++*/


// 申请售后（实物）
export function applyGoodsService(data) {
	return http("order/service/apply", data)
}


// 售后订单列表（实物）
export function getServiceOrderList(data) {
	return http("order/service/getLists", data)
}

// 售后订单详情（实物）
export function getAfterOrderDetails(data) {
	return http("order/service/getDetails", data)
}

// 售后订单退货（实物）
export function serviceHandleDelivery(data) {
	return http1("order/service/handleDelivery", data)
}
 






/*++++++++++++++++++++++++++++++++ 掌柜工作台 ++++++++++++++++++++++++++++++++*/



// 获取达人工作台数据
export function getUserWork(data) {
	return http("/user/Wallet/getWallet", data)
}

// 升级奖励金额
export function getReward(data) {
	return http("/user/Wallet/getReward", data)
}

// 升级奖励金额金额到账
export function applyReward(data) {
	return http1("/user/Wallet/applyReward", data)
}

// 升级奖励列表
export function getRewardOrder(data) {
	return http("/user/Wallet/getListsByReward", data)
}

// 我的订单收益
export function getProfitOrderByUser(data) {
	return http("/user/Wallet/getListsByProfit", data)
}

// 我的团队订单收益
export function getProfitOrderByTeam(data) {
	return http("/user/Wallet/getListsByTeam", data)
}

// 我的团队（上级）
export function getSuperior(data) {
	return http("/user/team/getSuperior", data)
}

// 我的团队列表
export function getUserTeam(data) {
	return http("/user/team/getUserTeam", data)
}

// 订单提醒
export function remindForCards(data) {
	return http1("user/Wallet/remindForCards", data)
}



/*++++++++++++++++++++++++++++++++ 掌柜提现 ++++++++++++++++++++++++++++++++*/

// 核验达人资金
export function checkUserWallet(data) {
	return http("/user/Wallet/checkWallet", data)
}

//提现列表--达人
export function userTixianList(data) {
	return http("/user/Withdraw/getLists", data)
}

//提现列表详情--达人
export function userTixianListDetail(data) {
	return http("/user/Withdraw/getDetails", data)
}

//申请提现--达人
export function userApplyTixian(data) {
	return http1("/user/Withdraw/apply", data)
}

//银行卡列表--达人
export function userBankList(data) {
	return http("/user/Bank/getLists", data)
}

//银行卡默认--达人
export function userBankDefault(data) {
	return http("/user/Bank/getDefault", data)
}

//删除银行卡--达人
export function userDeleteBankCard(data) {
	return http1("/user/Bank/delete", data)
}

//添加银行卡--达人
export function userAddBankCard(data) {
	return http1("/user/Bank/create", data)
}






 







/*++++++++++++++++++++++++++++++++ 商家 ++++++++++++++++++++++++++++++++*/

//登录
export function MerchatLogin(data) {
	return http1("/shop/user/login", data)
}
// 商家登录获取验证码 sendsmsLogin
export function sendsmsLogin(data) {
    return http1("/shop/user/sendsmsLogin", data)
}

// 商家验证码登录 vcodeLogin
export function vcodeLogin(data) {
    return http1("/shop/user/vcodeLogin", data)
}

// 商户微信授权绑定
export function wechatAuth(data) {
	return http1("shop/user/wechatAuth", data)
}
// 商户微信授权登录
export function authLogin(data) {
	return http1("shop/user/authLogin", data)
}

// 商家修改密码获取验证码 sendsmsResetPass
export function sendsmsResetPass(data) {
	return http1("/shop/user/sendsmsResetPass", data)
}
// 商家重置密码 resetPass
export function resetPass(data) {
	return http1("/shop/user/resetPass", data)
}



//商家详情
export function BuessDetail(data) {
	return http("/shop/shop/getDetails", data)
}

// 获取商家店铺资金数据 wallet
export function getShopWallet(data) {
	return http("/shop/shop/getWallet", data)
}

//点击修改电话
export function Modify(data) {
	return http("/shop/shop/updateShop", data)
}

//商家核销验证
export function verificationCheck(data) {
	return http("/order/cards/checkCards", data)
}

//商家核销订单详情
export function verificationOrderDetail(data) {
	return http("/order/cards/getDetailsByShop", data)
}

// 商家核销
export function verificationOrder(data) {
	return http("/order/cards/writeOffCards", data)
}



//核检店铺资金
export function ChackMoney(data) {
	return http("/shop/shop/checkWallet", data)
}


 

//申请提现--商户
export function applyTixian(data) {
	return http("/shop/Withdraw/apply", data)
}

//提现列表--商户
export function TixianList(data) {
	return http("/shop/Withdraw/getLists", data)
}

//提现列表详情--商户
export function TixianListDetail(data) {
	return http("/shop/Withdraw/getDetails", data)
}



//商家银行卡列表--商户
export function BankList(data) {
	return http("/shop/Bank/getLists", data)
}

export function BankDefault(data) {
	return http("/shop/Bank/getDefault", data)
}

//删除银行卡--商户
export function DeleteBankCard(data) {
	return http1("/shop/Bank/delete", data)
}

//添加银行卡--商户
export function AddBankCard(data) {
	return http1("/shop/Bank/create", data)
}

 
// 商家预约记录统计
export function shopReserveTotal(data) {
	return http("shop/order/getStatisticsByReserve", data)
}
// 商家预约记录列表
export function shopReserveList(data) {
	return http("shop/order/getListsByReserve", data)
}


//商家核销记录列表
export function verificationList(data) {
	return http("/shop/order/getListsByCheck", data)
}

//商家核销记录数据统计
export function getStatistics(data) {
	return http("/shop/order/getStatisticsByCheck", data)
}


// 商户核销统计
export function getCheckByGoods(data) {
	return http("shop/order/getCheckByGoods", data)
}






//创建商品分享
export function createShare(data) {
	return http("/mall/goods/createShare", data)
}

 
// 我的访客列表
export function getGoodsVisitor(data) {
	return http("/logs/Share/getGoodsVisitor", data)
}

// 我的分享的商品访客列表
export function getGoodsVisitorUser(data) {
	return http("/logs/Share/getGoodsVisitorUser", data)
}



