import Vue from 'vue'
Vue.config.productionTip = false
import App from './App.vue'
import store from './store'
import router from './router'

//import SStorage from './storage/session.js';
//Vue.prototype.$SStorage = SStorage;
import LStorage from './storage/local.js';
Vue.prototype.$LStorage = LStorage;

import login from "./common/islogin"
Vue.prototype.$login = login;

import {
	checkSite
} from "@/http/index";

//  设置全局分享
import {
	onUserShare
} from '@/common/wxJssdk';

//  守卫路由
router.beforeEach((to, from, next) => {
	console.log(to)
	if (to.meta.title) {
		document.title = to.meta.title
	}

	if (to.query.referrer_id) {
		//  设置推广员缓存
		LStorage.setItem('referrerInfo', to.query.referrer_id)
	}
	//	验证站点
	checkSite(to.query.site_code, function() {
		if (to.path === '/login' || to.path === '/regin') {
			next()
		} else {
			//  执行登录判断
			login.ifLogin(to.fullPath, function(){
                next()
            }) 
		}
	})
})

router.beforeResolve((to, from, next) => {
	let curSiteInfo = LStorage.getItem('curSiteInfo');
    
    //  设置微信分享参数
    
	let shareInfo = {
		title: '饭十荟',
		desc: '这是一个致力于让老百姓花的少吃的好的平台',
		imgUrl: window.location.origin + '/logo.png',
		link: '',
	}
	if (curSiteInfo && curSiteInfo.site_code) {
		shareInfo.link = '/?site_code=' + curSiteInfo.site_code
		let userInfo = LStorage.getItem('userInfo') 
		if (userInfo && userInfo.user_code) {
            shareInfo.link += '&referrer_id=' + ((userInfo.user_types == 2) ? userInfo.user_code : 0)
		}
	}
    store.commit("changeWechatConfig", {
        appid: curSiteInfo.appid,
        pageurl: (window.location.origin + to.fullPath)
    });
	//	执行分享
	onUserShare(shareInfo, () => {});
	next();
})

//  海报
import VueCanvasPoster from 'vue-canvas-poster'
Vue.use(VueCanvasPoster)


// ------------------------------------------------------------
import "rest-css"
import "./scss/app"

import Mint from "mint-ui";
import "mint-ui/lib/style.css"
Vue.use(Mint)

import {
	Navbar,
	TabItem,
	Swipe,
	SwipeItem
} from 'mint-ui';
Vue.component(TabItem.name, TabItem);
Vue.component(Navbar.name, Navbar);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);

import {
	Toast,
	Dialog,
} from 'vant';
import Vant from 'vant';
import 'vant/lib/index.css'
Vue.use(Vant);
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import "./lib/iconfont.css"

//引入微信js方法
//import wx from 'weixin-js-sdk';
//Vue.prototype.$wx = wx;


//复制到粘贴板插件
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

//空页面组件
import NoData from './components/NoData'
Vue.component('no-data', NoData)

//  加载更多
import LoadMore from './components/LoadMore'
Vue.component('load-more', LoadMore)

//公众号二维码组件
import WeChatPublicQRCode from './components/WeChatPublicQRCode'
Vue.component('we-chat-public-q-r-code', WeChatPublicQRCode)


//  全局过滤器
import * as filters from '@/filters/index';
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
