<!-- 加载更多 -->
<template>
	<div class=''>
		<p class="more" v-if="loadLastText" @click="getMore">查看更多</p>
		<div class="more-load" v-else>到底啦〜想不到你看到了这里^_^</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			loadLastText: {
				type: Boolean,
				default () {
					false
				}
			}
		},
		// 接收父组件参数
		data() {
			return {}
		},
		// 监听属性 类似于data概念
		computed: {},
		// 监控data中的数据变化
		watch: {},
		//方法集合
		methods: {
			getMore() {
				this.$emit('getMore')
			}
		},
	}
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	.more {
		padding-top: 0.35rem;
		padding-bottom: 0.35rem;
		box-sizing: border-box;
		font-size: 0.25rem;
		text-align: center;
	}

	.more-load {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		box-sizing: border-box;
		font-size: 0.24rem;
		line-height: 0.88rem;
		color: #999;
		text-align: center;
	}
</style>
