<template>
    <div id="app">
        <template>
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
        </template>

        <template>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </template>
        <make-phone :popup="makePhone"></make-phone>
        <wechat-customer-service :popup="wechat"></wechat-customer-service>
    </div>
</template>

<script>
    window.onload = function() {
        document.addEventListener("touchstart", function(event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });
        document.addEventListener("gesturestart", function(event) {
            event.preventDefault();
        });
    };

    import MakePhone from "@/components/MakePhone";
    import WechatCustomerService from "@/components/WechatCustomerService";

    export default {
        components: {
            MakePhone,
            WechatCustomerService
        },
        data() {
            return {
                v: 2
            };
        },
        created() {},
        mounted() {},
        computed: {
            makePhone() {
                return this.$store.state.makePhone;
            },
            wechat() {
                return this.$store.state.wechat;
            }
        },
        methods: {}
    };
</script>


<style lang="scss">
    *::-webkit-scrollbar {
        display: none;
    }

    #app {
        max-width: 7.5rem;
        margin: 0 auto;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .flex-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }
</style>
