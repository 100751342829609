<!-- 空页面 -->
<template>
	<div class="no-data">
		<img src="@/assets/nothing.png" class="null-img" />
		<p class="text">{{ content }}</p>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			content: {
				type: String,
				default () {
					return "暂无内容，敬请期待~";
				},
			},
		},
		// 接收父组件参数
		data() {
			return {};
		},
		// 监听属性 类似于data概念
		computed: {},
		// 监控data中的数据变化
		watch: {},
		//方法集合
	};
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	.no-data {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-direction: column;
		height: 300px;

		.null-img {
			width: 50vw;
		}

		.text {
			font-size: 0.24rem;
			color: #999999;
		}
	}
</style>
