<template>
	<div class="qr-code-box">
		<div class="title">公众号</div>
		<div class="flex-box">
			<img :src="wxQRCode" class="ewm" alt />
			<img src="@/assets/images/zhiwen.png" class="zw" alt />
		</div>
		<!-- <div class="text">长按关注公众号，进入商城查看详情。</div> -->
		<img src="@/assets/guanzhu.gif" alt class="gz-icon" />
	</div>
</template>

<script>
	export default {
		name: "",
		components: {},
		props: {},
		data() {
			return {
				wxQRCode: ""
			};
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			let kefu = this.$LStorage.getItem("curSiteInfo");
			if (kefu) {
				this.wxQRCode = kefu.wx_qrcode;
			}
		},
		methods: {}
	};
</script>

<style scoped lang="scss">
	.qr-code-box {
		background-color: #ffffff;
		border-radius: 0.05rem;
		padding: 0.2rem;
		margin-bottom: 0.24rem;

		.title {
			border-left: #e50012 solid 0.07rem;
			font-size: 0.28rem;
			color: #000000;
			padding-left: 0.16rem;
		}

		.flex-box {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 0.2rem;

			.ewm {
				width: 2.47rem;
			}

			.zw {
				width: 1.21rem;
				margin-left: 0.2rem;
			}
		}

		.text {
			text-align: center;
			font-size: 0.26rem;
			color: #333333;
		}

		.gz-icon {
			width: 4.2rem;
			margin: 0 auto;
			display: block;
		}
	}
</style>
