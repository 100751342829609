import {
	updateToken
} from "../http/api"
import LStorage from '../storage/local.js';

export default {
	//  执行登录
	execLogin(curUrl) {
		let loginUrl = 'http://' + document.domain + '/login';
		let appid = LStorage.getItem('siteInfo').appid;
		window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + LStorage.getItem(
				'siteInfo').appid + '&redirect_uri=' + encodeURI(loginUrl) +
			'&response_type=code&scope=snsapi_base&state=' + encodeURI(curUrl) + '#wechat_redirect';
	},

	//  执行注册
	execRegin(curUrl) {
		let zappid = 'wx2072f5b290784490'
		let reginUrl = 'http://' + document.domain + '/regin';
		window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + zappid +
			'&redirect_uri=' + encodeURI(reginUrl) + '&response_type=code&scope=snsapi_userinfo&state=' + encodeURI(
				curUrl) + '#wechat_redirect';
	},

	//  验证用户登录
	ifLogin(curUrl, func) {
		/* 
		LStorage.setItem('userToken', {
			"user_token": "ZhGe99BlINwMSBEw0xVd24R3OEn5Fq0s",
			"expire": 1647259182,
			"refresh_token": "bd72c2b113d420aaba14f7b29277666d"
		})
		LStorage.setItem('userInfo', { 
            "user_code": "V99KGoGU9a",
            "headimgurl": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKLiaqL7ibyaujCTicHQQaDxFJUpBCxyic5Oy69iapqg0v6UPT7pqWg03awIibp4zbEHm2GgKmd6ic6Xkibyw/132",
            "nickname": "周良",
            "user_name": "周良",
            "user_phone": "18735191695",
            "wx_account": "",
            "user_types": 2,
            "referrer_lv": 2,
            "is_qrcode": 1
		})
		func()
		*/ 
		let userToken = LStorage.getItem('userToken')
		if (userToken && userToken.user_token) {
			let nowTime = Math.floor(new Date().getTime() / 1000)
			let fiveTime = userToken.expire * 1 - nowTime * 1
			if (fiveTime <= 300) { //说明token再过5分钟过期
				updateToken({
					data: {
						user_token: userToken.user_token,
						refresh_token: userToken.refresh_token,
					}
				}).then((res) => {
					if (res.code == 200) {
						LStorage.setItem('userToken', res.data);
						//console.log("更新TOKEN成功，去執行")
						func()
					} else {
						LStorage.removeItem('userToken');
                        LStorage.removeItem('userInfo');
						//console.log("更新TOKEN失败")
						this.execLogin(curUrl)
					}
				}).catch((err) => {
					//console.log("更新TOKEN响应错误")
                    LStorage.removeItem('userToken');
                    LStorage.removeItem('userInfo');
					this.execLogin(curUrl) //执行登录
				})
                //console.log('执行完更新')
			} else {
				//console.log("验证TOKEN成功，去執行")
				func()
			}
		} else {
			//console.log("没有缓存 跳转登录")
            LStorage.removeItem('userToken');
            LStorage.removeItem('userInfo');
			this.execLogin(curUrl) //执行登录
		}
	}
}
