<!-- 电话弹窗 -->
<template>
	<div class="popup" v-if="popup">
		<!-- 内容 -->
		<div class="popup-content">
			<p>客服</p>
			<div class="content-bg">
				<div class="popup-content-renwu">
					<img src="@/assets/rewu.png" alt />
				</div>
				<ul class="popup-content-wrap">
					<li>
						<div>联系电话：{{ phone }}</div>
						<p @click="call">
							<img src="@/assets/dianhua.png" alt />
						</p>
					</li>
					<li v-clipboard:copy="wxNum" v-clipboard:success="onCopy" v-clipboard:error="onError">
						<div>微信号：{{ wxNum }}</div>
						<p class="copy">点击复制</p>
					</li>
					<li>
						<div>工作时间：{{ workTime }}</div>
					</li>
				</ul>
			</div>
			<div class="close" @click="closepopup">X</div>
		</div>
		<div class="yinyi"></div>

		<div class="fuzhi" v-if="show">复制成功...</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			popup: {
				type: Boolean,
				default () {
					return false;
				}
			}
		},
		data() {
			return {
				phone: "",
				wxNum: "",
				workTime: "",
				show: false
			};
		},
		// 监听属性 类似于data概念
		computed: {},
		// 监控data中的数据变化
		watch: {},
		//方法集合
		methods: {
			//关闭弹窗
			closepopup() {
				this.$store.dispatch("changeMakePhone", false);
			},
			//拨打电话
			call() {
				window.location.href = "tel:" + this.phone;
			},
			// 监听复制成功
			onCopy(e) {
				this.$toast.success({
					duration: 200,
					message: "复制成功"
				});
				this.show = true;
				setTimeout(() => {
					this.show = false;
				}, 1000);
			},
			// 监听复制失败
			onError(e) {
				this.$toast.fail("复制失败");
			}
		},
		mounted() {
			let configInfo = this.$LStorage.getItem("curSiteInfo");
			if (configInfo) {
				this.phone = configInfo.kefu_phone1;
				this.wxNum = configInfo.kefu_wx1;
				this.workTime = configInfo.kefu_work_time;
			}
		},
	};
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	// 弹窗
	.popup {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 9999;

		.popup-content {
			width: 5.17rem;
			height: max-content;
			position: fixed;
			top: 20%;
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 100;

			p:nth-child(1) {
				width: 100%;
				height: 0.88rem;
				line-height: 0.88rem;
				background: #ff6521;
				font-size: 0.36rem;
				color: #fff;
				text-align: center;
				border-radius: 0.1rem 0.1rem 0 0;
			}

			.content-bg {
				background: #ffffff;
				padding-bottom: 0.88rem;
				border-radius: 0 0 0.1rem 0.1rem;
			}

			.popup-content-renwu {
				width: 100%;
				height: 2.63rem;
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				background: #fff;

				img {
					height: 1.8rem;
					display: block;
				}

			}

			.popup-content-wrap {
				width: 4.73rem;
				margin: 0 auto;

				li {
					width: 100%;
					background: #ffd8c7;
					display: flex;
					justify-content: space-between;
					border-radius: 0.09rem;
					padding: 0 0.25rem 0 0.28rem;
					box-sizing: border-box;
					margin-bottom: 0.3rem;
					font-size: 0.24rem;

					div {
						height: 0.74rem;
						line-height: 0.74rem;
						font-size: 0.26rem;
						color: #343232;
					}

					p {
						padding-top: 0.15rem;
						box-sizing: border-box;

						img {
							width: 0.37rem;
							height: 0.45rem;
							display: block;
						}

					}

					.copy {
						font-size: 0.26rem;
						color: #ff6521;
					}
				}

				li:last-child {
					margin-bottom: 0;
				}
			}

			.close {
				position: absolute;
				top: -0.22rem;
				right: -0.22rem;
				width: 0.45rem;
				height: 0.45rem;
				background: #ffd8c7;
				opacity: 0.6;
				border-radius: 50%;
				font-size: 0.24rem;
				color: #5e000f;
				text-align: center;
				line-height: 0.45rem;
			}
		}

		.yinyi {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
		}

		.fuzhi {
			position: fixed;
			top: 42%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 30%;
			height: 30px;
			display: flex;
			color: #fff;
			border-radius: 20px;
			background: rgba(0, 0, 0, 0.6);
			justify-content: center;
			align-items: center;
			z-index: 1000;
			font-size: 13px;
		}
	}
</style>
