<!-- 微信客服弹窗 -->
<template>
	<!-- //平台客服 -->
	<div class="popup" v-if="popup">
		<!-- 内容 -->
		<div class="popup-content">
			<p class="kefu">提示</p>
			<div class="popup-content-renwu">
				<img src="@/assets/rewu.png" alt />
			</div>
			<ul class="popup-content-wrap">
				<li>
					<p>添加饭十荟客服微信</p>
					<p>微信号:{{ wxNum }}</p>
					<p>回复“推广”口令，立即开通此权限</p>
				</li>
				<li v-clipboard:copy="wxNum" v-clipboard:success="onCopy" v-clipboard:error="onError">
					<p>复制微信 添加好友</p>
				</li>
			</ul>
			<div class="close" @click="closepopup">X</div>
		</div>
		<div class="yinyi"></div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			popup: {
				type: Boolean,
				default () {
					return false;
				}
			}
		},
		data() {
			return {
				wxNum: ""
			};
		},
		// 监听属性 类似于data概念
		computed: {},
		// 监控data中的数据变化
		watch: {},
		//方法集合
		methods: {
			//关闭弹窗
			closepopup() {
				this.$store.dispatch("changeWechat", false);
			},
			// 监听复制成功
			onCopy(e) {
				this.$toast.success("复制成功");
			},
			// 监听复制失败
			onError(e) {
				this.$toast.fail("复制失败");
			}
		},
		// 生命周期 - 创建完成（可以访问当前this实例）
		created() {},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			let configInfo = this.$LStorage.getItem("curSiteInfo");
			if (configInfo) {
				this.wxNum = configInfo.kefu_wx2;
			}
		},
	};
</script>
<style lang='scss' scoped>
	//@import url(); 引入公共css类
	// 弹窗
	.popup {
		width: 100%;
		height: 100%;

		.popup-content {
			width: 5.17rem;
			height: 6.25rem;
			background: #fff;
			border-radius: 0.08rem;
			position: fixed;
			top: 20%;
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 100;

			.kefu {
				width: 100%;
				height: 0.88rem;
				line-height: 0.88rem;
				background: #ff6521;
				border-radius: 0.07rem 0.07rem 0 0;
				font-size: 0.36rem;
				color: #fff;
				text-align: center;
			}

			.popup-content-renwu {
				width: 100%;
				height: 2.63rem;
				// padding-left: 1.92rem;
				// padding-top: 0.24rem;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 1.32rem;
					// height: 1.8rem;
					display: block;

				}
			}

			.popup-content-wrap {
				width: 4.73rem;
				margin: 0 auto;
				height: 0.74rem;

				li:nth-child(1) {
					width: 4.38rem;
					font-size: 0.26rem;
					text-align: center;

					p:nth-child(1) {
						margin-bottom: 0.23rem;
					}

					p:nth-child(3) {
						margin-top: 0.23rem;
						font-size: 0.24rem;
						text-align: center;
					}
				}

				li:nth-child(2) {
					margin-top: 0.23rem;
					background: #ff6521;
					border-radius: 0.32rem;
					width: 4.67rem;
					height: 0.75rem;
					color: #fff;
					text-align: center;
					line-height: 0.75rem;
					font-size: 0.3rem;
				}
			}

			.close {
				position: absolute;
				top: -0.32rem;
				right: -0.32rem;
				width: 0.65rem;
				height: 0.65rem;
				background: #ffd8c7;
				opacity: 0.6;
				border-radius: 50%;
				font-size: 0.4rem;
				color: #5e000f;
				text-align: center;
				line-height: 0.6rem;
			}
		}

		.yinyi {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
		}
	}
</style>
