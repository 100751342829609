import Vue from 'vue'
import axios from "axios"
import {
    Toast
} from 'vant';
import LStorage from '../storage/local.js';
import store from '../store/index.js';

import { randomString } from '../common/data.js';

//引入MD5加密
import md5 from "js-md5";

let time = new Date().getTime();
let spotHeaderObj = {};
spotHeaderObj.clientkey = "12345678";
spotHeaderObj.devicekey = md5(time + randomString(20));
spotHeaderObj.timestamp = time;

const instance = axios.create({
    //baseURL: "http://jxaaa.yizhezhinan.com/mini.php", //测试 
    baseURL: "http://csadmin.yizhezhinan.com/mini.php", //线上
    timeout: 30000,
    headers: spotHeaderObj
})

// 封装请求
export function http(url, reqObj = '') {
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: reqObj.methods || 'POST',
            data: reqObj.data,
        }).then(res => {
            if ((res.status >= 200 && res.status < 300) || res.status === 304) {
                if (res.data.code === 200) {
                    resolve(res.data)
                } else {
                    //Toast.fail(res.data.msgs);
                    resolve(res.data)
                }
            } else {
                Toast.fail(res.statusText);
                reject(res)
            }
        }).catch(err => {
            Toast.fail(err.msgs);
            reject(err)
        })
    })
}


// 封装请求
export function http1(url, reqObj = '') {
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: reqObj.methods || 'POST',
            data: reqObj.data,
            // headers: reqObj.header
        }).then(res => {
            if ((res.status >= 200 && res.status < 300) || res.status === 304) {
                resolve(res.data)
            } else {
                Toast.fail(res.statusText);
                reject(res)
            }
        }).catch(err => {
            Toast.fail(err.msgs);
            reject(err)
        })
    })
}

export function getSiteInfo(reqObj = {}, url = '/index/site/getDetails') {
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: 'POST',
            data: reqObj,
        }).then(res => {
            if ((res.status >= 200 && res.status < 300) || res.status === 304) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export function checkSite(sitecode, func) {
    /*
	//	测试代码
	LStorage.setItem('siteInfo', {"site_code":"035101","appid":"wx2072f5b290784490"}); 
	LStorage.setItem('curSiteInfo', {"site_code":"035101","site_name":"太原站","location_lng":"112.437262","location_lat":"37.902564","appid":"wx2072f5b290784490","kefu_phone1":"13012341234","kefu_phone2":"13012341234","kefu_wx1":"13012341234","kefu_wx2":"13012341234","wx_qrcode":"http://yizhe-ceshi.oss-cn-beijing.aliyuncs.com/4c2d06ef9d0b42936219483b18513addimage.jpeg","kefu_work_time":"09:00 - 21:00"} ); 
    store.dispatch("changecurSiteInfo", {"site_code":"035101","site_name":"太原站","location_lng":"112.437262","location_lat":"37.902564","appid":"wx2072f5b290784490","kefu_phone1":"13012341234","kefu_phone2":"13012341234","kefu_wx1":"13012341234","kefu_wx2":"13012341234","wx_qrcode":"http://yizhe-ceshi.oss-cn-beijing.aliyuncs.com/4c2d06ef9d0b42936219483b18513addimage.jpeg","kefu_work_time":"09:00 - 21:00"});
	func();
	return;
    */
    let siteInfo = LStorage.getItem('siteInfo');
    // 获取site_code 缓存 判断缓存 site_code 是否存在
    if (siteInfo && siteInfo.site_code) {
        //console.log("执行获取站点加载")
        //  判断 当前 site_code 与访问 site_code 参数是否一致
        if (sitecode && (sitecode != siteInfo.site_code)) {
            //console.log("执行获取站点变更加载")
            //  不一致 删除缓存, 获取当前site_code 参数配置
            getSiteInfo({
                site_code: sitecode
            }).then(function(ret) {
                if (ret.code == 200) {
                    LStorage.setItem('siteInfo', {
                        'site_code': ret.data.site_code,
                        'appid': ret.data.appid
                    });
                    LStorage.setItem('curSiteInfo', ret.data);
                    store.dispatch("changecurSiteInfo", ret.data);
                    func();
                } else {
                    Toast.fail('获取站点信息错误-10001');
                }
            }).catch((err) => {
                Toast.fail('执行站点信息查询错误-10002')
            })
        } else {
            func();
        }
    } else {
        //console.log("执行首次默认加载")
        getSiteInfo({
            site_code: '035101'
        }).then(function(ret) {
            if (ret.code == 200) {
                LStorage.setItem('siteInfo', {
                    'site_code': ret.data.site_code,
                    'appid': ret.data.appid
                });
                LStorage.setItem('curSiteInfo', ret.data);
                store.dispatch("changecurSiteInfo", ret.data);
                func();
            } else {
                Toast.fail('获取站点信息错误-10003');
            }
        }).catch((err) => {
            Toast.fail('执行站点信息查询错误-10004')
        })
    }
}

