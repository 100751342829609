// 过滤电话
const noPassByMobile = (str) => {
    if (null != str && str != undefined) {
        var pat = /(\d{3})\d*(\d{4})/;
        return str.replace(pat, "$1****$2");
    } else {
        return "";
    }
};
// 过滤姓名
const noPassByName = (str) => {
    if (null != str && str != undefined) {
        var text = '';
        text += str.substring(0, 1)
        for (var i = 1; i < str.length; i++) {
            text += '*'
        }
        return text;
    } else {
        return "";
    }
};

export {
    noPassByMobile,
    noPassByName
}
