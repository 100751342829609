import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        curSiteInfo: JSON.parse(localStorage.getItem('curSiteInfo')) || {}, //当前站点信息
        isShowChangeLocation: false, //是否显示切换城市
        makePhone: false,
        wechat: false,
        shareInfo: {
            title: '饭十荟',
            desc: '这是一个致力于让老百姓花的少吃的好的平台',
            link: '',
            imgUrl: window.location.origin + '/logo.png'
        },
        wechatConfig: {
            appid: 'wx2072f5b290784490',
            pageurl: window.location.origin
        },
        addressInfo: null,
        bankInfo: null,
        ShopBankInfo: null
    },
    mutations: { //定义全局方法 修改值 内部使用
        //修改当前站点信息
        changecurSiteInfo(state, data) {
            state.curSiteInfo = data
        },
        changeMakePhone(state, data) {
            state.makePhone = data
        },
        changeWechat(state, data) {
            state.wechat = data
        },
        changeShowLoction(state, data) {
            state.isShowChangeLocation = data
        },
        changeAddressInfo(state, data) {
            state.addressInfo = data
        },
        changeBankInfo(state, data) {
            state.bankInfo = data
        },
        changeShopBankInfo(state, data) {
            state.ShopBankInfo = data
        },
        changeWechatConfig(state, data) {
            state.wechatConfig = data
        }
    },
    actions: { //外部调用方法
        //修改当前站点信息
        changecurSiteInfo(context, data) {
            context.commit('changecurSiteInfo', data)
        },
        changeMakePhone(context, data) {
            context.commit('changeMakePhone', data)
        },
        changeWechat(context, data) {
            context.commit('changeWechat', data)
        },
        changeShowLoction(context, data) {
            context.commit('changeShowLoction', data)
        },
        changeAddressInfo(context, data) {
            context.commit('changeAddressInfo', data)
        },
        changeBankInfo(context, data) { 
            context.commit('changeBankInfo', data)
        },
        changeShopBankInfo(context, data) {
            context.commit('changeShopBankInfo', data)
        },
        changeWechatConfig(context, data) {
            context.commit('changeWechatConfig', data)
        }
    },
    modules: {

    }
})

export default store