const ls = window.localStorage

export default {
    getItem(key) {
        return JSON.parse(ls.getItem(key))
    },

    setItem(key, val) {
        ls.setItem(key, JSON.stringify(val))
    },

    removeItem(key) {
        ls.removeItem(key)
    },

    clear() {
        ls.clear()
    },

    keys() {
        return ls.keys()
    }
};
