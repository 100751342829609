import {
    getWxJssdkConfig,
    uploadWxFileSingle
} from '../http/api';
import store from '../store/index.js'
import wx from 'weixin-js-sdk';

//微信JSSDK配置
async function config(callBack) { 
    let res = await getWxJssdkConfig({
        data: store.state.wechatConfig
    });
    //  console.log(res.data)
    wx.config({
        debug: res.data.debug,
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: [
            'checkJsApi',
            'getLocation',
            'openLocation',
            'scanQRCode',
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'chooseWXPay',
            'chooseImage',
            'uploadImage',
            'getLocalImgData',
            'closeWindow'
        ]
    });
    wx.ready(function() {
        callBack(true)
    });
    wx.error(function(err) {
        console.log('配置失败')
        console.log( err)
    });
}

// 用户分享
export function onUserShare(options, callBack) {
    config((status) => {
        if (status) {
            let link = window.location.origin + options.link
            wx.updateAppMessageShareData({ //获取“分享给朋友”按钮点击状态及自定义分享内容接口
                title: options.title, // 分享标题
                desc: options.desc, // 分享描述
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: options.imgUrl, // 分享图标
                success: function() {
                    callBack({
                        status: true
                    })
                }
            })

            wx.updateTimelineShareData({ //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
                title: options.title, // 分享标题
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: options.imgUrl, // 分享图标
                success: function() {
                    callBack({
                        status: true
                    })
                }
            })
        }
    })
}


// ===========================================================

// 获取地理位置
export function getLocationInfo(type, callBack) {
    config((status) => {
        if (status) {
            wx.getLocation({
                type: type, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function(res) {
                    //console.log('获取定位 success')
                    callBack(res)
                },
                fail: function(error) {
                    //console.log('获取定位 fail')
                    callBack(false)
                },
                cancel: function() {
                    //console.log('获取定位 cancel')
                    callBack(false)
                }
            });
        } else {
            //console.log('获取定位失败')
            callBack(false)
        }
    });
}

// 使用微信内置地图查看位置接口
export function openLocation(options) {
    config((status) => {
        if (status) {
            wx.openLocation({
                latitude: options.latitude, // 纬度，浮点数，范围为90 ~ -90
                longitude: options.longitude, // 经度，浮点数，范围为180 ~ -180。
                name: options.name, // 位置名
                address: options.address, // 地址详情说明
                scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
                infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
            });
        }
    });
}

//微信扫一扫
export function scanQRCode(callBack) {
    config((status) => {
        if (status) {
            wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                desc: '请扫描二维码',
                scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: function(res) {
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    setTimeout(() => {
                        callBack({
                            status: true,
                            result
                        })
                    }, 1000)
                },
            });
        }
    })
}



/* 二期新增接口 */
//拍照或从手机相册中选图接口
const chooseImage = (count, callBack) => {
    wx.chooseImage({
        count: count, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: res => {
            let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            callBack({
                status: true,
                localIds
            })
        }
    });
}
//获取本地图片接口
export const getLocalImgData = (count) => {
    config(status => {
        if (status) {
            chooseImage(count, response => {
                if (response.status) {
                    let localIds = response.localIds;
                    for (let i = 0; i < localIds.length; i++) {
                        wx.getLocalImgData({
                            localId: localIds[i], // 图片的localID
                            success: res => {
                                var localData = res
                                    .localData;
                            }
                        });
                    }
                }
            })
        }
    })
}

//上传图片接口
export const uploadImage = (options = {}, callBack) => {
    config(status => {
        if (status) {
            let count = options.count || 1,
                type = options.type || 1;
            chooseImage(count, response => {
                if (response.status) {
                    let localIds = response.localIds,
                        imgUrl = [];
                    for (let i = 0; i < localIds.length; i++) {
                        wx.uploadImage({
                            localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: res => {
                                var serverId = res.serverId; // 返回图片的服务器端ID
                                uploadWxFileSingle({
                                    data: {
                                        appid: store.state.wechatConfig.appid,
                                        media_id: serverId,
                                        type: type
                                    }
                                }).then(res => {
                                    if (res.code == 200) {
                                        imgUrl.push(res.data.material_url);
                                        if (imgUrl.length == count) {
                                            callBack({
                                                status: true,
                                                imgs: imgUrl
                                            })
                                        } else {
                                            callBack({
                                                status: false,
                                                msg: '上传图片失败'
                                            })
                                        }
                                    }
                                })
                            }
                        });
                    }
                }
            })
        } else {
            callBack({
                status: false,
                msg: '上传图片失败'
            })
        }
    }, '', true)
}

//发起支付
export const chooseWXPay = (datas, callBack) => {
    config(status => {
        if (status) {
            wx.chooseWXPay({
                timestamp: datas.timeStamp,
                nonceStr: datas.nonceStr, // 支付签名随机串，不长于 32 位
                package: datas.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: datas.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: datas.paySign, // 支付签名
                success: res => {
                    // 支付成功后的回调函数
                    callBack(true)
                },
                error: error => {
                    // console.log("支付失败");
                    callBack(false)
                },
                cancel: function() {
                    // console.log("取消支付");       
                    callBack(false)
                },
                fail: () => {
                    // console.log("失败");
                    callBack(false)
                }
            });
        }
    })
}

//关闭当前网页窗口
export const closeWindow = () => {
    config(status => {
        if (status) {
            wx.closeWindow();
        }
    })
}
