import Vue from 'vue';
import VueRouter from 'vue-router';

const routes = [{
        path: "",
        redirect: "/home"
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login'),
        meta: {
            title: '登录中',
            keepAlive: false
        }
    },
    {
        path: '/regin',
        name: 'regin',
        component: () => import('../views/regin'),
        meta: {
            title: '注册',
            keepAlive: false
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/Home'),
        meta: {
            title: '首页',
            keepAlive: true,
        }
    },

    {
        path: '/search',
        name: 'Search',
        component: () => import('../views/position/search'),
        meta: {
            title: '搜索',
            keepAlive: false
        }
    },

    {
        path: '/search/info',
        name: 'SearchInfo',
        component: () => import('../views/position/SearchInfo'),
        meta: {
            title: '搜索结果',
            keepAlive: true
        }
    },

    {
        path: '/position',
        name: 'Position',
        component: () => import('../views/position/position'),
        meta: {
            title: '选择城市',
            keepAlive: false
        }
    },
    {
        path: '/nearby',
        name: 'Nearby',
        component: () => import('../views/home/nearby'),
        meta: {
            title: '附近好物',
            keepAlive: false,
        }
    },


    {
        path: '/classify',
        name: 'Classify',
        component: () => import('../views/classify/classify'),
        meta: {
            title: '分类',
            keepAlive: true
        }
    },
	
    {
        path: '/school',
        name: 'School',
        component: () => import('../views/school/school'),
        meta: {
            title: '商学院',
            keepAlive: false
        }
    },
	
    {
        path: '/newtutorial',
        name: 'Newtutorial',
        component: () => import('../views/school/newtutorial'),
        meta: {
            title: '文章列表',
            keepAlive: false
        }
    },
    {
        path: '/graphicdetails',
        name: 'Graphicdetails',
        component: () => import('../views/school/graphicdetails'),
        meta: {
            title: '文章详情',
            keepAlive: false
        }
    },
 
    {
        path: '/details',
        name: 'Details',
        component: () => import('../views/details/details'),
        meta: {
            title: '商品详情',
            keepAlive: false
        }
    },
	
    {
        path: '/allstore',
        name: 'allstore',
        component: () => import('../views/Allstore/Allstore'),
        meta: {
            title: '全部门店',
            keepAlive: false
        }
    },

    {
        path: '/business',
        name: 'Business',
        component: () => import('../views/Business/Business'),
        meta: {
            title: '商家详情',
            keepAlive: false
        }
    },

    {
        path: '/settlement',
        name: 'Settlement',
        component: () => import('../views/details/settlement'),
        meta: {
            title: '订单结算',
            keepAlive: false
        },
    },
    {
        path: '/result',
        name: 'Result',
        component: () => import('../views/details/result'),
        meta: {
            title: '订单结果',
            keepAlive: false
        }
    },




    {
        path: '/my',
        name: 'My',
        component: () => import('../views/my/my'),
        meta: {
            title: '个人中心',
            keepAlive: false
        }
    },
    {
        path: '/personal',
        name: 'Personal',
        component: () => import('../views/my/personal'),
        meta: {
            title: '个人资料',
            keepAlive: false
        }
    },


	{
        path: '/tanlent',
        name: 'Talent',
        component: () => import('../views/my/promoter/Talent'),
        meta: {
            title: '掌柜工作台',
            keepAlive: false
        }
    }, //达人
    {
        path: '/inviteposter',
        name: 'Inviteposter',
        component: () => import('../views/my/change/InvitePoster'),
        meta: {
            title: '邀请好友',
            keepAlive: false
        }
    }, //邀请海报
    {
        path: '/invite/users',
        name: 'InviteUsers',
        component: () => import('../views/my/change/InviteUsers'),
        meta: {
            title: '注册成为掌柜',
            keepAlive: false
        }
    },
    //邀请-好友注册
    {
        path: '/reg/success',
        name: 'RegSuccess',
        component: () => import('../views/my/change/RegSuccess'),
        meta: {
            title: '提示',
            keepAlive: false
        }
    }, //邀请-好友注册-注册成功
    {
        path: '/myteam',
        name: 'Myteam',
        component: () => import('../views/my/change/myteam'),
        meta: {
            title: '我的团队',
            keepAlive: false
        }
    },


    {
        path: '/apply',
        name: 'Apply',
        component: () => import('../views/my/promoter/apply'),
        meta: {
            title: '申请提现',
            keepAlive: false
        }
    }, //申请提现
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/my/totle/account/account'),
        meta: {
            title: '我的银行卡',
            keepAlive: false
        }
    }, //提现账户
    {
        path: '/addBank',
        name: 'AddBank',
        component: () => import('../views/my/totle/account/addBank'),
        meta: {
            title: '添加银行卡',
            keepAlive: false
        }
    }, //添加银行卡
 
    {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: () => import('../views/my/promoter/Withdrawal'),
        meta: {
            title: '我的账户',
            keepAlive: false
        }
    },
    {
        path: '/Hwithdrawal',
        name: 'Hwithdrawal',
        component: () => import('../views/my/promoter/Hwithdrawal'),
        meta: {
            title: '提现详情',
            keepAlive: false
        }
    }, //提现明细-提现详情
	
 
  
    {
        path: '/MyorderProfit',
        name: 'MyOrderProfit',
        component: () => import('../views/my/promoter/MyorderProfit'),
        meta: {
            title: '我的订单收益',
            keepAlive: false
        }
    },
    {
        path: '/applypromoter',
        name: 'Applypromoter',
        component: () => import('../views/my/change/applypromoter'),
        meta: {
            title: '饭佣说明',
            keepAlive: false
        }
    }, //推广规则
 //达人--我的账户
    {
        path: '/reward',
        name: 'Reward',
        component: () => import('../views/my/promoter/UpReward'),
        meta: {
            title: '升级奖励',
            keepAlive: false
        }
    }, //达人--升级奖励
    {
        path: '/sharegood',
        name: 'Sharegood',
        component: () => import('../views/my/change/sharegood'),
        meta: {
            title: '我的访客',
            keepAlive: false
        }
    }, //我分享的商品--我的访客
    {
        path: '/my/goods/visitor',
        name: 'MyGoodsVisitor',
        component: () => import('../views/my/promoter/MyGoodsVisitor'),
        meta: {
            title: '访客',
            keepAlive: false
        }
    }, //我分享的商品--我的访客-访客


    {
        path: '/allorder',
        name: 'Allorder',
        component: () => import('../views/order/allorder'),
        meta: {
            title: '全部订单',
            keepAlive: false
        }
    },


    {
        path: '/newproblems',
        name: 'Newproblems',
        component: () => import('../views/my/login/Newproblems'),
        meta: {
            title: '问题列表',
            keepAlive: false
        }
    }, //新人问答
    {
        path: '/pagedetails',
        name: 'PageDetails',
        component: () => import('../views/my/login/pagedetails'),
        meta: {
            title: '详情',
            keepAlive: false
        }
    },





    //商家登录
    {
        path: '/merchatlogin',
        name: 'Merchatlogin',
        component: () => import('../views/my/login/merchatlogin'),
        meta: {
            title: '商家登录',
            keepAlive: false
        }
    },
    {
        path: '/phonelogin',
        name: 'PhoneLogin',
        component: () => import('../views/my/login/ShopPhoneLogin'),
        meta: {
            title: '手机号登录',
            keepAlive: false
        }
    }, //手机号登录
    {
        path: '/wechatlogin',
        name: 'WechatLogin',
        component: () => import('../views/my/login/ShopWechatLogin'),
        meta: {
            title: '微信登录',
            keepAlive: false
        }
    }, //微信登录
    {
        path: '/restpassword',
        name: 'Restpassword',
        component: () => import('../views/my/login/restpassword'),
        meta: {
            title: '重置密码',
            keepAlive: false
        }
    },
	
	
    {
        path: '/merchantwork',
        name: 'Merchantwork',
        component: () => import('../views/my/login/merchantwork'),
        meta: {
            title: '商家工作台',
            keepAlive: false
        }
    },
    {
        path: '/storeinformation',
        name: 'Storeinformation',
        component: () => import('../views/my/login/storeinformation'),
        meta: {
            title: '店铺信息',
            keepAlive: false
        }
    },
    {
        path: '/hexiaorecords',
        name: 'Hexiaorecords',
        component: () => import('../views/my/login/hexiaorecords'),
        meta: {
            title: '核销记录',
            keepAlive: false
        }
    },
    {
        path: '/writeOffStatistics',
        name: 'WriteOffStatistics',
        component: () => import('../views/my/login/WriteOffStatistics'),
        meta: {
            title: '核销统计',
            keepAlive: false
        }
    },
    {
        path: '/confirmhexiao',
        name: 'Confirmhexiao',
        component: () => import('../views/my/login/Confirmhexiao'),
        meta: {
            title: '订单详情',
            keepAlive: false
        }
    }, //确认核销
    {
        path: '/hexiaosucess',
        name: 'Hexiaosucess',
        component: () => import('../views/my/login/hexiaosucess'),
        meta: {
            title: '核销成功',
            keepAlive: false
        }
    },
	
    {
        path: '/shopApply',
        name: 'shopApply',
        component: () => import('../views/my/login/apply'),
        meta: {
            title: '申请提现',
            keepAlive: false
        }
    },
 
    {
        path: '/shopAccount',
        name: 'shopAccount',
        component: () => import('../views/my/totle/shopaccount/account'),
        meta: {
            title: '我的银行卡',
            keepAlive: false
        }
    }, //提现账户
    {
        path: '/shopaddBank',
        name: 'shopaddBank',
        component: () => import('../views/my/totle/shopaccount/addBank'),
        meta: {
            title: '添加银行卡',
            keepAlive: false
        }
    },
	{
		path: '/shopWithdraw',
		name: 'shopWithdraw',
		component: () => import('../views/my/login/withdraw'),
		meta: {
			title: '提现记录',
			keepAlive: false
		}
	},
	{
		path: '/shopWithdrawList',
		name: 'shopWithdrawList',
		component: () => import('../views/my/login/withdrawlist'),
		meta: {
			title: '提现记录列表',
			keepAlive: false
		}
	},
 
 
 
    // 之前路由
    {
        path: '/Hwithdrawal1',
        name: 'Hwithdrawal1',
        component: () => import('../views/my/totle/tixianDetail/Hwithdrawal'),
    },
    {
        path: '/performance',
        name: 'performance',
        component: () => import('../views/my/promoter/performance'),
    },
    {
        path: '/memberDetail',
        name: 'memberDetail',
        component: () => import('../views/my/change/memberDetail'),
    },
    {
        path: '/Commission',
        name: 'Commission',
        component: () => import('../views/my/change/Commission'),
    }, //饭佣订单

    {
        path: '/Ordermangement',
        name: 'Ordermangement',
        component: () => import('../views/my/login/Ordermangement'),
        meta: {
            title: '预约管理',
            keepAlive: false
        }
    },
    {
        path: '/member',
        component: () => import('../views/member/member')
    },
	
	
	
    { //实物商城
        path: '/ShopIndex',
        name: 'ShopIndex',
        component: () => import('../views/shopMall/ShopIndex'),
        meta: {
            title: '精选好物',
            keepAlive: true
        }
    },
    { //实物商城分类
        path: '/ShopMall',
        name: 'ShopMall',
        component: () => import('../views/shopMall/shopMall'),
        meta: {
            title: '实物商城',
            keepAlive: false
        }
    },
    { //实物商城搜索
        path: '/ShopMall/search',
        name: 'ShopMallSearch',
        component: () => import('../views/shopMall/Search/search'),
        meta: {
            title: '搜索',
            keepAlive: false
        }
    },
    { //实物商城搜索结果
        path: '/ShopMall/search/info',
        name: 'ShopMallSearchInfo',
        component: () => import('../views/shopMall/Search/SearchInfo'),
        meta: {
            title: '搜索结果',
            keepAlive: true
        }
    },
    { //实物商城商品详情
        path: '/ShopGoodsDetails',
        name: 'ShopGoodsDetails',
        component: () => import('../views/shopMall/goods-details'),
        meta: {
            title: '商品详情',
            keepAlive: false
        }
    },
    { //实物商城结算页
        path: '/ShopMallSettlement',
        name: 'ShopMallSettlement',
        component: () => import('../views/shopMall/settlement'),
        meta: {
            title: '订单结算',
            keepAlive: false
        }
    },
    { //实物商城结算成功页
        path: '/ShopMallPaySuccess',
        name: 'ShopMallPaySuccess',
        component: () => import('../views/shopMall/pay-success'),
        meta: {
            title: '支付结果',
            keepAlive: false
        }
    },
    { //实物商城订单
        path: '/ShopMallOrder',
        name: 'ShopMallOrder',
        component: () => import('../views/shopMall/order'),
        meta: {
            title: '实物订单',
            keepAlive: false
        }
    },
    { //实物商城订单详情
        path: '/ShopMallOrderDetails',
        name: 'ShopMallOrderDetails',
        component: () => import('../views/shopMall/order-details'),
        meta: {
            title: '订单详情',
            keepAlive: false
        }
    },
    { //售后申请
        path: '/AfterSale',
        name: 'AfterSale',
        component: () => import('../views/shopMall/after-sale'),
        meta: {
            title: '售后申请',
            keepAlive: false
        }
    },
    { //售后订单
        path: '/AfterSaleOrder',
        name: 'AfterSaleOrder',
        component: () => import('../views/shopMall/order1'),
        meta: {
            title: '售后订单',
            keepAlive: false
        }
    },
    { //售后订单详情
        path: '/AfterSaleOrderDetails',
        name: 'AfterSaleOrderDetails',
        component: () => import('../views/shopMall/order-details1'),
        meta: {
            title: '售后订单详情',
            keepAlive: false
        }
    },

    { //地址列表
        path: '/AddressList',
        name: 'AddressList',
        component: () => import('../views/mall/address-list'),
        meta: {
            title: '地址列表',
            keepAlive: false
        }
    },
    { //地址编辑
        path: '/AddressEdit',
        name: 'AddressEdit',
        component: () => import('../views/mall/address-edit'),
        meta: {
            title: '地址编辑',
            keepAlive: false
        }
    },
    {
        path: '/appointment',
        name: 'appointment',
        component: () => import('../views/order/appointment'),
        meta: {
            title: '订单预约',
            keepAlive: false
        }
    }, //订单预约
    {
        path: '/order/date',
        name: 'OrderDate',
        component: () => import('../views/order/OrderDate'),
        meta: {
            title: '订单预约',
            keepAlive: false
        }
    }, //订单预约
    {
        path: '/WechatAuth',
        name: 'WechatAuth',
        component: () => import('../views/my/login/WechatAuth'),
        meta: {
            title: '微信授权',
            keepAlive: false
        }
    },
	
	
	
	
	

	
    {
        path: '/mall',
        name: 'Mall',
        component: () => import('../views/mall/mall'),
        meta: {
            title: '积分商城',
            keepAlive: false
        }
    },
    {
        path: '/rule',
        name: 'Rule',
        component: () => import('../views/mall/rule'),
        meta: {
            title: '积分规则',
            keepAlive: false
        }
    },
    { //积分明细
        path: '/PointsDetailed',
        name: 'PointsDetailed',
        component: () => import('../views/mall/detailed'),
        meta: {
            title: '积分明细',
            keepAlive: false
        }
    },
    { //积分明细详情
        path: '/PointsDetails',
        name: 'PointsDetails',
        component: () => import('../views/mall/details'),
        meta: {
            title: '积分明细详情',
            keepAlive: false
        }
    },
    { //积分商品详情
        path: '/GoodsDetails',
        name: 'GoodsDetails',
        component: () => import('../views/mall/goods-details'),
        meta: {
            title: '积分商品详情',
            keepAlive: false
        }
    },
    {
        path: '/PointsMallSettlement',
        name: 'PointsMallSettlement',
        component: () => import('../views/mall/settlement'),
        meta: {
            title: '订单结算',
            keepAlive: false
        }
    },
    {
        path: '/PaySuccess',
        name: 'PaySuccess',
        component: () => import('../views/mall/pay-success'),
        meta: {
            title: '支付成功',
            keepAlive: false
        }
    },
    { //积分商城订单
        path: '/MallOrder',
        name: 'MallOrder',
        component: () => import('../views/mall/order'),
        meta: {
            title: '积分订单',
            keepAlive: false
        }
    },
    { //积分商城订单详情
        path: '/MallOrderDetails',
        name: 'MallOrderDetails',
        component: () => import('../views/mall/order-details'),
        meta: {
            title: '积分订单详情',
            keepAlive: false
        }
    },
	
	
	
    {
        path: '/nouse',
        name: 'Nouse',
        component: () => import('../views/order/checkorder/nouse'),
        meta: {
            title: '订单详情',
            keepAlive: false
        }
    },

		
	
]

Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

export default router
