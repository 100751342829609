

export function randomString(len =32) {
	len = len || 32;
	var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
	var maxPos = $chars.length;
	var pwd = '';
	for (var i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
}


// 根据时间戳计算倒计时
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}

export function timeStamp2String(time, type) {
    var datetime = new Date();
    datetime.setTime(time);
    var year = datetime.getFullYear();
    var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
    var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
    var hour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
    var minute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
    var second = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
    if (type == '1') {
        return year + "年" + month + "月" + date + "日";
    }
    if (type == '2') {
        return year + "-" + month + "-" + date;
    }
    if (type == '3') {
        return month + "-" + date;
    }
    if (type == '4') {
        return month;
    }
    if (type == '5') {
        return date;
    }
    if (type == '6') {
        return year + "." + month + "." + date + " " + hour + ":" + minute + ":" + second;
    }
    if (type == '7') {
        return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
    }
    if (type == '8') {
        return year + "-" + month + "-" + date + " " + hour + ":" + minute;
    }
    if (type == '9') {
        return month + "-" + date + " " + hour + ":" + minute;
    }
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

// 数组长度转换
export function changeArr(oldArr, num) {
    let newArr = [],
        length = Math.ceil(oldArr.length / num);
    for (var i = 0; i < length; i++) {
        if (i == length - 1) {
            newArr[i] = oldArr.slice(i * num)
        } else {
            newArr[i] = oldArr.slice(i * num, (i + 1) * num)
        }
    }
    return newArr
}

// 米单位换算
export function changeVisit(visit) {
    if (visit > 10000) {
        return (visit / 10000).toFixed(1) + '万'
    }
    return visit
}

// 米单位换算
export function changeDis(distance) {
    var text = ''
    if (distance < 1000) {
        text = distance + 'm'
    } else {
        text = (distance / 1000).toFixed(2) + 'km'
    }
    return text
}

//网络图片转base64
export function getBase64Image(url, callBack) {
    var image = new Image()
    image.src = url + '?v=' + Math.random() // 处理缓存
    image.crossOrigin = '*' // 支持跨域图片
    image.onload = function() {
        var base64 = drawBase64Image(image)
        callBack({
            status: true,
            base64
        })
    }
}

function drawBase64Image(img) {
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.width, img.height)
    var dataURL = canvas.toDataURL('image/png')
    return dataURL
}
 
export function getQueryParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}
